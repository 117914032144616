@import "~bootstrap";


.card {
    padding: 50px;
    font-size: 50px;
    cursor: pointer;
}

.card .number {
    min-width: 113px;
    text-align: center;
}

.card.single {
    font-size: 100px;
    line-height: 163px;
}

.numerator {
    padding-bottom: 5px;
}

.denominator {
    padding-top: 5px;
    border-top: 3px solid black;
}

.bg-danger .denominator, .bg-success .denominator {
    border-top: 3px solid white;
}
